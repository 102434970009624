import React, { useState } from 'react';
import axios from 'axios';

const RegisterForm = () => {
  const [studentFirstName, setStudentFirstName] = useState('');
  const [studentLastName, setStudentLastName] = useState('');
  const [parentFirstName, setParentFirstName] = useState('');
  const [parentLastName, setParentLastName] = useState('');
  const [studentEmail, setStudentEmail] = useState('');
  const [selectedClub, setSelectedClub] = useState('');
  const [selectedGrade, setSelectedGrade] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  // const API_URL = "http://localhost:1337/api";
  const API_URL = "http://157.245.254.60:1337/api";
  // const API_URL = "https://pto-strapi-backend.herokuapp.com";



  const clubs = [
    { id: 1, name: 'Coding Club' },
    { id: 2, name: 'Chess Club' },
    { id: 3, name: 'Arts and Crafts Club' },
    { id: 4, name: 'Little Medical School Club' },
  ];

  const grades = [
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5' },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.get(
        `${ API_URL}/clubs?filters[clubName][$eq]=${selectedClub}`
      );
      const clubData = response.data.data[0];

      if (clubData) {
        const existingStudents = clubData.students || [];
        const waitlistStudents = clubData.waitlist || [];

        if (existingStudents.length < 10) {
          const updatedStudents = [
            ...existingStudents,
            {
              studentFirstName: studentFirstName,
              studentLastName: studentLastName,
              parentFirstName: parentFirstName,
              parentLastName: parentLastName,
              grade: selectedGrade,
              email: studentEmail,
              phone: phoneNumber,
              status: 'registered',
              paymentStatus: 'pending',
            },
          ];
          await axios.put(`${ API_URL}/clubs/${clubData.documentId}`, {
            data: { students: updatedStudents },
          });

          setMessage(`Successfully registered for ${selectedClub}. Please send the payement to PTO. Thank you!`);
          resetForm();
        } else {
          const updatedWaitlist = [
            ...waitlistStudents,
            {
              studentFirstName: studentFirstName,
              studentLastName: studentLastName,
              parentFirstName: parentFirstName,
              parentLastName: parentLastName,
              grade: selectedGrade,
              email: studentEmail,
              phone: phoneNumber,
              status: 'waitlist',
            },
          ];
          await axios.put(`${API_URL}/clubs/${clubData.documentId}`, {
            data: { waitlist: updatedWaitlist },
          });
          setMessage(
            `The club is full. You have been added to the waitlist for ${selectedClub}.`
          );
          resetForm();
        }
      } else {
        setMessage('Club not found.');
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  const resetForm = () => {
    setStudentFirstName('');
    setStudentLastName('');
    setParentFirstName('');
    setParentLastName('');
    setStudentEmail('');
    setSelectedClub('');
    setPhoneNumber('');
    setSelectedGrade('');
  };

  return (
    <div style={styles.formContainer}>
      <h2 style={styles.heading}>Register for After-School Club</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
      <div style={styles.formGroup}>
          <label htmlFor="club" style={styles.label}>Select Club:</label>
          <select
            id="club"
            value={selectedClub}
            onChange={(e) => setSelectedClub(e.target.value)}
            style={styles.input}
            required
          >
            <option value="">--Select a club--</option>
            {clubs.map((club) => (
              <option key={club.id} value={club.name}>
                {club.name}
              </option>
            ))}
          </select>
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="studentFirstName" style={styles.label}>Student First Name:</label>
          <input
            type="text"
            id="studentFirstName"
            value={studentFirstName}
            onChange={(e) => setStudentFirstName(e.target.value)}
            style={styles.input}
            required
          />
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="studentLastName" style={styles.label}>Student Last Name:</label>
          <input
            type="text"
            id="studentLastName"
            value={studentLastName}
            onChange={(e) => setStudentLastName(e.target.value)}
            style={styles.input}
            required
          />
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="parentFirstName" style={styles.label}>Parent First Name:</label>
          <input
            type="text"
            id="parentFirstName"
            value={parentFirstName}
            onChange={(e) => setParentFirstName(e.target.value)}
            style={styles.input}
            required
          />
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="parentLastName" style={styles.label}>Parent Last Name:</label>
          <input
            type="text"
            id="parentLastName"
            value={parentLastName}
            onChange={(e) => setParentLastName(e.target.value)}
            style={styles.input}
            required
          />
        </div>

        <div style={styles.formGroup}>
          <label htmlFor="studentGrade" style={styles.label}>Student Grade:</label>
          <select
            id="studentGrade"
            value={selectedGrade}
            onChange={(e) => setSelectedGrade(e.target.value)}
            style={styles.input}
            required
          >
            <option value="">--Select Student Grade--</option>
            {grades.map((grade) => (
              <option key={grade.id} value={grade.name}>
                {grade.name}
              </option>
            ))}
          </select>
        </div>

        <div style={styles.formGroup}>
          <label htmlFor="email" style={styles.label}>Parents Email:</label>
          <input
            type="email"
            id="email"
            value={studentEmail}
            onChange={(e) => setStudentEmail(e.target.value)}
            style={styles.input}
            required
          />
        </div>

        <div style={styles.formGroup}>
          <label htmlFor="phone" style={styles.label}>Parents Phone Number:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            style={styles.input}
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" // Optional pattern for validation
            placeholder="123-456-7890"
            required
          />
        </div>


        <button type="submit" style={styles.button}>Register</button>
      </form>

      {message && <p style={styles.message}>{message}</p>}
    </div>
  );
};

const styles = {
  formContainer: {
    width: '400px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  heading: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formGroup: {
    marginBottom: '15px',
  },
  label: {
    marginBottom: '5px',
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
  },
  button: {
    padding: '10px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  message: {
    marginTop: '15px',
    textAlign: 'center',
  },
};

export default RegisterForm;
