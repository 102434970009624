function Donate(){
    return (
        <div style={styles.container}>
          <h2 style={styles.title}>Support Our Cause</h2>
          <p style={styles.description}>
            Your generous donation helps us make a difference. Join us in supporting our mission!
            A donation to the Al-Noor Academy PTO DIRECTLY supports and benefits the students and teachers of Al-Noor Academy.
           Over the years, funds raised by the PTO have not only been used to host educational events and programs for the students, but
            have also allowed us to take on larger projects such as purchase iPads for the lower building and maintaining the playground annually.
            As our resources grow our ability to help the school thrive is endless. We can restock libraries, purchase new technology for each classroom, help with classroom supplies, and
             to enrich the educational programs and extracurriculars offered to our children.
             To accomplish this, the Al-Noor Academy PTO depends on the strong financial commitment and dedication of friends, family and members of the community.
             If you have been wanting to get involved or help with our fundraising initiatives, and have been unable to do so, here is the perfect solution!
              We encourage you and thank you for any donation you make to help the PTO in all that we strive to do. Jazak Allahu Khair for your contributions. 
          </p>
          <button style={styles.button}>Donate Now using Zelle to PTO@Alnooracademy.org</button>
        </div>
      );
    }
    
    const styles = {
      container: {
        backgroundColor: '#003366', // Dark blue background
        color: '#FFD700', // Yellow text
        padding: '20px',
        borderRadius: '8px',
        textAlign: 'center',
        maxWidth: '400px',
        margin: 'auto',
      },
      title: {
        color: '#FFD700', // Yellow text color
        marginBottom: '10px',
      },
      description: {
        color: '#ffffff', // White text for better readability
        marginBottom: '20px',
      },
      button: {
        backgroundColor: '#FFD700', // Yellow background for button
        color: '#003366', // Dark blue text color
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
      },
}
export default Donate;