function AboutUs(){
     return (
    <div style={styles.container}>
      <h2 style={styles.title}>Our Mission</h2>
      <p style={styles.missionStatement}>
        The Alnoor Academy Parent Teacher Organization's purpose is to strengthen, enhance, and support the educational and social environment of the school.
         To serve as a liaison between the parents, teachers, and staff.
          Our goal is to compliment the school curriculum with additional opportunites for parents, teachers, and students to learn, socialize, and most importantly grow.
           By the will of Allah, we strive to work hard to create a beautiful and fruitful school experience for the children and to support the teachers and staff in any way we can.
      </p>
      <div style={styles.valuesContainer}>
        <h3 style={styles.valuesTitle}>Our Core Values</h3>
        <ul style={styles.valuesList}>
          <li>Community Engagement</li>
          <li>Inclusive Support</li>
          <li>Academic Excellence</li>
          <li>Collaboration</li>
        </ul>
      </div>
    </div>
  );
}

const styles = {
  container: {
    backgroundColor: '#ffffff', // white background
    color: '#060001', // black text color
    padding: '20px',
    borderRadius: '8px',
    textAlign: 'center',
    maxWidth: '600px',
    margin: 'auto',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    color: '#0045b1', // Blue color for title
    marginBottom: '15px',
    fontSize: '24px',
  },
  missionStatement: {
    color: '#060001', // black text for the mission statement
    fontSize: '16px',
    lineHeight: '1.6',
    marginBottom: '20px',
  },
  valuesContainer: {
    backgroundColor: '#e4e9f1', // Gray background for the values section
    padding: '15px',
    borderRadius: '8px',
    marginTop: '20px',
  },
  valuesTitle: {
    color: '#060001', // black text color for values title
    marginBottom: '10px',
    fontSize: '20px',
  },
  valuesList: {
    listStyleType: 'none',
    padding: 0,
    color: '#060001', // Black text color for values
    fontSize: '16px',
  },
}
export default AboutUs;