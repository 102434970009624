//component to display the list of Events and their details.

// src/components/RestaurantList.js
import React, { useState } from 'react';
import { ApolloClient, InMemoryCache, useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Container,
  Row,
  Col
} from 'reactstrap';
import AfterSchoolClubs from './AfterSchoolClubs';

//Define the GraphQL query to fetch the list of events.
const GET_PTOEVENTS = gql`
query {
  
    events {
        documentId,
        title,
        description,
        img {
          url
        }
      }
}
`;

//Create the EventList Component that fetches and filters the Event list based on user input.

const EventsList = () => {
    const navigate = useNavigate(); // Initialize the navigation hook
  // const [query, setQuery] = useState('');
  // console.log('query from search box: '+query);
  const [ptoeventID, setptoEventID] = useState(null);
  const [title, setTitle] = useState(null);

  // TESTINGGGGGG
  const client = new ApolloClient({
    // uri: "http://157.245.254.60:1337/graphql",
   uri:  "https://www.alnooracademypto.org/api/graphql",
    cache: new InMemoryCache(),
  });
  client
    .query({
      query: GET_PTOEVENTS
    })
    .then((result) => console.log(result))
    .catch(err => console.error(err));


  const { loading, error, data } = useQuery(GET_PTOEVENTS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
//   if (!data) return <p>Not found</p>;
//   console.log(`Query Data: ` + JSON.stringify(data.events.data[0].id) ); // the id of the first restaurant
//   console.log(`Query Data: ` + JSON.stringify(data.events.data[0].attributes.title) ); // the name of the first restaurant
//   console.log(`Query Data: ` + JSON.stringify(data.events.data[0].attributes.description[0].children[0].text) ); // the description of the first restaurant
//   console.log(`Query Data: ` + JSON.stringify(data.events.data[0].attributes.image.data[0].attributes.url) ); // the image of the first restaurant
  
//   const eventsArray = Object.entries(data);
//   console.log('events array : '+eventsArray);
  
 function handleEventClick(documentId,title){
    
    // setptoEventID(documentId);
    
    // //    alert(title);
    // navigate(`/events/${title}`);

    setptoEventID(documentId);  // Save the ID if needed elsewhere
    setTitle(title);
  navigate(`/events/${title}`);  // Navigate to the event page based on the title
    
    console.log(title);


 }


//   const searchQuery = data.events.data.filter((ptoEvent) =>{
//     return ptoEvent.attributes.title.toLowerCase()
//   })
  
//   console.log('search:'+search);
//   console.log('searchQuery: '+  JSON.stringify(searchQuery));
  
  
 
  // definet renderer for Dishes
//  const renderDishes = (restaurantID) => {
//     return (<Dishes restId={restaurantID}> </Dishes>)
//   };

//  if(searchQuery.length > 0){

//    let eventtId = searchQuery[0].id
//    const eventList = searchQuery.map((ptoEvent) => {
//      const imageUrl = ptoEvent.attributes.image.data[0].attributes.url;
   
//     console.log('query search image: '+ JSON.stringify(ptoEvent.attributes.image.data[0].attributes.url))
//     console.log('res.id: '+ptoEvent.id);
//     console.log('the restaurant name: '+ JSON.stringify(ptoEvent.attributes.title))
   
//      setEventID(ptoEvent.id);

  // Check if data exists and map over the events to display them
   return (
    <Container>
    <Row>
      {data.events.map((ptoEvent) => {
        const imageUrl = ptoEvent.img.url;
        const title = ptoEvent.title;
        const description = ptoEvent.description;
        const documentId= ptoEvent.documentId;

        return (
          <Col md="4" key={documentId}>
            <Card>
              {/* {imageUrl && <CardImg top width="100%" src={`http://localhost:1337${imageUrl}`} alt={title} />} */}
              {/* {imageUrl && <CardImg top width="100%" src={`http://157.245.254.60:1337${imageUrl}`} alt={title} />} */}
              {imageUrl && <CardImg top width="100%" src={`https://www.alnooracademypto.org/api/${imageUrl}`} alt={title} />}
              <CardBody>
                <CardTitle tag="h5">{title}</CardTitle>
                <CardText>{description}</CardText>
                <Button onClick={() =>  handleEventClick(documentId,title)}>View Details</Button>
              </CardBody>
            </Card>
          </Col>
        );
      })}
    </Row>
  </Container>
    
 )

}

export default (EventsList);
