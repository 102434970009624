
import { useState } from "react";
import { registerUser } from "./auth";
import {AuthContext} from "./AuthContext";
import Cookie from "js-cookie";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
// import AppNavbar from "./Navbar";
function JoinUs() {
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      phone: '',
      childName: '',
      message: '',
    });
    const [loading, setLoading] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(false); // State to track button disabled
  
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      
    };
    // const API_URL = process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337/api";
    
    const API_URL = process.env.NEXT_PUBLIC_API_URL || "http://157.245.254.60:1337/api";
    // const registerUser = (name,email,childName,phone,message) => {
    //   //prevent function from being ran on the server
    //   if (typeof window === "undefined") {
    //     return;
    //   }
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post(`${API_URL}/members`, {name,email,childName,phone,message})
    //       .then((res) => {
    //         //set token response from Strapi for server validation
    //         Cookie.set("token", res.data.jwt);
    
    //         //resolve the promise to set loading to false in SignUp form
    //         resolve(res);
    //         //redirect back to home page for restaurance selection
    //         // useNavigate("/login");
    //         //  const navigate = useNavigate();
    //         // navigate('/login');
    //           //redirect back to home page for restaurance selection
    //           // useNavigate("/Home");
    //       })
    //       .catch((error) => {
    //         //reject the promise and pass the error object back to the form
    //         reject(error);
    //       });
    //   });
    // };
    const registerUser = (name, email, childName, phone, message) => {
      if (typeof window === "undefined") {
        return;
      }
    
      return new Promise((resolve, reject) => {
        const userData = {
          data: { // Wrap the payload in a "data" object
            name,
            email,
            childName,
            phone,
            message,
          },
        };
    
        console.log('User data being sent:', userData); // Log the wrapped data
    
        axios
          .post(`${API_URL}/members`, userData)
          .then((res) => {
            Cookie.set("token", res.data.jwt);
            resolve(res);
          })
          .catch((error) => {
            console.log(`Error in register: ${error.response?.data || error.message}`);
            reject(error.response?.data || error.message);
          });
      });
    };
    
    
  
    const handleSubmit = (e) => {
      e.preventDefault();
      setButtonDisabled(true); // Disable the button when submitted
      
      alert(`Thank you for joining the PTO, ${formData.name}!`);
      console.log(formData);
      // Add form submission logic (e.g., send to an API) here
      setLoading(true);
                        registerUser(formData.name,formData.email, formData.childName,formData.phone,formData.message)
                          .then((res) => {
                            // set authed user in global context object
                            // login(res.data.user)
                            // authContext.setUser(res.data.user);
                            setLoading(false);
                            console.log(`registered user: ${JSON.stringify(res.data)}`)
                            
                          })
                          .catch((error) => {
                            console.log(`error in register: ${error}`)
                            //setError(error.response.data);
                            setLoading(false);
                          });
    };
  
    return (
      <>
      {/* <AppNavbar/> */}
      <section id="join" className="container mb-5">
        <h2 className="text-center mb-4">Join the PTO</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="inputName">Full Name</label>
              <input
                type="text"
                className="form-control"
                id="inputName"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Full Name"
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="inputEmail">Email</label>
              <input
                type="email"
                className="form-control"
                id="inputEmail"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="inputPhone">Phone</label>
              <input
                type="text"
                className="form-control"
                id="inputPhone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Phone Number"
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="inputChildName">Child's Name</label>
              <input
                type="text"
                className="form-control"
                id="inputChildName"
                name="childName"
                value={formData.childName}
                onChange={handleChange}
                placeholder="Child's Name"
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="inputMessage">Why do you want to join the PTO?</label>
            <textarea
              className="form-control"
              id="inputMessage"
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="4"
            />
          </div>

          <button type="submit" if
           className="btn btn-primary btn-block"
           disabled={isButtonDisabled}
           >Subscribe</button>
        </form>
      </section>
      </>
    );
  }
  export default JoinUs;