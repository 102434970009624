//Create Apollo Client Instance
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';


const cache = new InMemoryCache();
const link = new HttpLink({
  uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`
});
const client = new ApolloClient({
  cache,
  link
});
//===================================================
// const client = new ApolloClient({
//   // uri: "https://157.245.254.60:1337/graphql",

//    link: new HttpLink({
//   //   // uri: 'https://157.245.254.60:1337',
//   //   // uri: 'http://localhost:1337/graphql',

//      uri: "http://157.245.254.60:1337/graphql",

//   //   // uri: 'http://localhost:1337',
//   //   // uri:  'https://pto-strapi-backend.herokuapp.com',
//    }),
//   cache: new InMemoryCache(),
// });
//==============================================

export default client;

