function Jumbotron() {
    return (
      <div className="jumbotron text-center">
        <h1 className="display-4">Welcome to Al-Noor Academy PTO</h1>
        <p className="lead">Building a strong partnership between parents and teachers to support our students' success.</p>
        <a href="/joinus" className="btn btn-primary btn-lg" style={{ marginBottom: "20px" }}>Become a Member</a>
      </div>
    );
  }

  export default Jumbotron;