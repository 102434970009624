// import AppNavbar from './Navbar';
function Contact() {
    return (
      <>
      {/* <AppNavbar /> */}
      <section id="contact" className="container mb-5">
        <h2 className="text-center mb-4">Contact Us</h2>
        <div className="row">
          <div className="col-md-6 offset-md-3 text-center">
            <p><strong>Email:</strong> pto@alnooracademy.org</p>
            <p><strong>Phone:</strong> 781-784-0400</p>
            <p><strong>Address:</strong> 84 - 86 Chase Drive Sharon, MA 02067</p>
          </div>
        </div>
      </section>
      </>
    );
  }

  export default Contact;