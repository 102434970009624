import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Events from './components/Events'
import EventDetail from './components/EventDetail'
import AfterSchoolClubs from './components/AfterSchoolClubs';
import Contact from './components/Contact';
import Home from './components/Home'
import JoinUs from './components/JoinUs'
import CodingClub from './components/CodingClub'
import EidFun from './components/EidFun';
import TeacherAppriciation from './components/TeacherAppriciation';
import RegisterForm from'./components/RegisterForm';
import CalendarWithEvents from './components/CalendarWithEvents';
import AboutUs from './components/AboutUs';
import Donate from './components/Donate';
import AppNavbar from './components/Navbar';


// App.js
import React, { useState } from 'react';


function App() {
  return (
    
    <Router>
    <div>
      <AppNavbar />
      {/* <Jumbotron />
      <Events/>  */}
      
      {/* Define Routes for event pages */}
      <Routes>
        <Route path="/" element = {<Home/>}/>
        <Route path="/contact" element = {<Contact/>}/>
        <Route path="/joinus" element = {<JoinUs/>}/>
        {/* Static route for events list */}
        {/* <Route path="/events" element={<Events />} /> */}
        <Route path="/events" element={<CalendarWithEvents />} />
        {/* <Route path="/events/:id" element={<EventDetail />} />  {/*Dynamic Route */}
        {/* <Route path="/events/After-School Clubs" element={<AfterSchoolClubs />} /> */}
        {/* <Route path="/events/After-School Clubs/Coding Club" element={<CodingClub />} /> */} 
         {/* Dynamic route based on event title */}
         {/* <Route path="/events/:title" element={<EventDetail />} /> */}
         <Route path="/events/After School Clubs" element={<AfterSchoolClubs/>} />
         <Route path="/events/Eid Celebration" element={<EidFun/>} />
         <Route path="/events/Teacher Appreciation Week" element={<TeacherAppriciation/>} />
         <Route path="/events/After-School Clubs/Coding Club" element={<CodingClub/>}/>
         <Route path="/events/After-School Clubs/register" element={<RegisterForm />} />
         <Route path="/aboutus" element={<AboutUs />} />
         <Route path="/donate" element={<Donate />} />
      </Routes> 
    </div>
    
  </Router>
  );
}

// function Navbar() {
//   return (
//     <nav className="navbar navbar-expand-lg navbar-light bg-light">
//       <a className="navbar-brand" href="#">Al-Noor Academy PTO</a>
//       <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
//         <span className="navbar-toggler-icon"></span>
//       </button>
//       <div className="collapse navbar-collapse" id="navbarNav">
//         <ul className="navbar-nav ml-auto">
//           <li className="nav-item active">
//             <a className="nav-link" href="#">Home</a>
//           </li>
//           <li className="nav-item">
//             <a className="nav-link" href="#events">Events</a>
//           </li>
//           <li className="nav-item">
//             <a className="nav-link" href="#join">Join Us</a>
//           </li>
//           <li className="nav-item">
//             <a className="nav-link" href="#contact">Contact</a>
//           </li>
//         </ul>
//       </div>
//     </nav>
//   );
// }

// function Jumbotron() {
//   return (
//     <div className="jumbotron text-center">
//       <h1 className="display-4">Welcome to Al-Noor Academy PTO</h1>
//       <p className="lead">Building a strong partnership between parents and teachers to support our students' success.</p>
//       <a href="#join" className="btn btn-primary btn-lg">Become a Member</a>
//     </div>
//   );
// }



// function JoinUs() {
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     childName: '',
//     message: '',
//   });

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     alert(`Thank you for joining the PTO, ${formData.name}!`);
//     console.log(formData);
//     // Add form submission logic (e.g., send to an API) here
//   };

//   return (
//     <section id="join" className="container mb-5">
//       <h2 className="text-center mb-4">Join the PTO</h2>
//       <form onSubmit={handleSubmit}>
//         <div className="form-row">
//           <div className="form-group col-md-6">
//             <label htmlFor="inputName">Full Name</label>
//             <input
//               type="text"
//               className="form-control"
//               id="inputName"
//               name="name"
//               value={formData.name}
//               onChange={handleChange}
//               placeholder="Full Name"
//             />
//           </div>
//           <div className="form-group col-md-6">
//             <label htmlFor="inputEmail">Email</label>
//             <input
//               type="email"
//               className="form-control"
//               id="inputEmail"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//               placeholder="Email"
//             />
//           </div>
//         </div>
//         <div className="form-row">
//           <div className="form-group col-md-6">
//             <label htmlFor="inputPhone">Phone</label>
//             <input
//               type="text"
//               className="form-control"
//               id="inputPhone"
//               name="phone"
//               value={formData.phone}
//               onChange={handleChange}
//               placeholder="Phone Number"
//             />
//           </div>
//           <div className="form-group col-md-6">
//             <label htmlFor="inputChildName">Child's Name</label>
//             <input
//               type="text"
//               className="form-control"
//               id="inputChildName"
//               name="childName"
//               value={formData.childName}
//               onChange={handleChange}
//               placeholder="Child's Name"
//             />
//           </div>
//         </div>
//         <div className="form-group">
//           <label htmlFor="inputMessage">Why do you want to join the PTO?</label>
//           <textarea
//             className="form-control"
//             id="inputMessage"
//             name="message"
//             value={formData.message}
//             onChange={handleChange}
//             rows="4"
//           />
//         </div>
//         <button type="submit" className="btn btn-primary btn-block">Submit</button>
//       </form>
//     </section>
//   );
// }

// function Contact() {
//   return (
//     <section id="contact" className="container mb-5">
//       <h2 className="text-center mb-4">Contact Us</h2>
//       <div className="row">
//         <div className="col-md-6 offset-md-3 text-center">
//           <p><strong>Email:</strong> pto@alnooracademy.org</p>
//           <p><strong>Phone:</strong> 781-784-0400</p>
//           <p><strong>Address:</strong> 84 - 86 Chase Drive Sharon, MA 02067</p>
//         </div>
//       </div>
//     </section>
//   );
// }

// function Footer() {
//   return (
//     <footer className="bg-dark text-white text-center py-3">
//       <p>&copy; 2024 Website Design by <a>Shaima Nimeri</a>. All rights reserved.</p>
//     </footer>
//   );
// }

export default App;
