import alNoorAcademyLogo from './alNoorAcademyLogo.png';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


function AppNavbar() {
    // return (
    //   <nav className="navbar navbar-expand-lg navbar-light bg-light">
    //      <div className="navbar-logo">
    //     {/* Display the image in the navbar */}
    //     {/* <img src="/AlNoorAcademyLogo.png" alt="Logo" className="logo" /> */}
    //     <img src={alNoorAcademyLogo} alt="Logo" className="logo" style={{ width:'50px', height: 'auto', borderRadius:50, objectFit:'cover',  marginRight: "20px"}} />

    //   </div>
    //     <a className="navbar-brand" href="#">Al-Noor Academy PTO</a>
    //     <button 
    //         className="navbar-toggler"
    //         type="button" 
    //         data-toggle="collapse"
    //         data-target="#navbarNav" 
    //         aria-controls="navbarNav" 
    //         aria-expanded="false" 
    //         aria-label="Toggle navigation"
    //         >
    //       <span className="navbar-toggler-icon"></span>
    //     </button>
    //     <div className="collapse navbar-collapse" id="navbarNav">
    //       <ul className="navbar-nav ml-auto">
    //         <li className="nav-item active">
    //           <a className="nav-link" href="/">HOME</a>
    //         </li>
    //         <li className="nav-item active">
    //           <a className="nav-link" href="events">Events</a>
    //         </li>
    //         <li className="nav-item">
    //           <a className="nav-link" href="/joinus">Join Us</a>
    //         </li>
    //         <li className="nav-item">
    //           <a className="nav-link" href="/contact">Contact</a>
    //         </li>
    //       </ul>
    //     </div>
    //   </nav>
    // );



    return (
      <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <img src={alNoorAcademyLogo} alt="Logo" className="logo" style={{ width:'50px', height: 'auto', borderRadius:50, objectFit:'cover',  marginRight: "20px"}} />
        <Navbar.Brand href="/">Al-Noor Academy PTO</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="events">Events</Nav.Link>
            <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="/joinus">Join Us</NavDropdown.Item>
              <NavDropdown.Item href="/contact">
                Contact
              </NavDropdown.Item>
              <NavDropdown.Item href="/aboutus">About Us</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/donate">
                Donate
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    );
  }





  export default AppNavbar;