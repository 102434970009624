import React from 'react';

function EidFun() {
  return (
    <div>
      <h2>We are still planning this wonderful event.</h2>
      <p>
        We are in the process of updating the contents of this page. 
        Thank you for your patience!
      </p>
    </div>
  );
}

export default EidFun;
