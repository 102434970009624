import React from 'react';
import { useNavigate } from 'react-router-dom';

function AfterSchoolClubs(){
  const navigate = useNavigate(); // Initialize the navigation hook
  const handleEventClick = (club,index) => {
    // Handle the event logic here
    //  alert(`You clicked on: ${JSON.stringify(club)} `);
  
    // You could navigate to another page, open a modal, etc.
    // Navigate to a dynamic route based on event id
      // navigate(`/events/After-School Clubs/${club.title}`);
        navigate(`/events/After-School Clubs/register`);
      // navigate(`/events/${index}`);

      
    
  };

  const clubs = [
    {
      title: 'Coding Club',
      description: 'Learn about computer programming and robotics',
      imgSrc: 'https://via.placeholder.com/150',
    },
    {
      title: 'Chess Club',
      description: 'Learn to play chess ',
      imgSrc: 'https://via.placeholder.com/150',
    },
    {
      title: 'Arts and Crafts',
      description: 'Get creative and artistic',
      imgSrc: 'https://via.placeholder.com/150',
    },
    {
      title: 'Little medical school',
      description: 'Pretend to be a health care provider',
      imgSrc: 'https://via.placeholder.com/150',
    },
    {
      title: 'Soccer Club',
      description: 'Learn the proper techniques to control the ball and gain stamina',
      imgSrc: 'https://via.placeholder.com/150',
    },
   ,
  ];
    // const { id } = useParams();
 
        
    return (
      <>
      <section id="clubs" className="container mb-5">
        <h2 className="text-center mb-4"> After School Clubs</h2>
        <div className="row">
          {clubs.map((club, index) => (
            <div className="col-md-4" key={index}>
              <div className="card">
                <img src={club.imgSrc} className="card-img-top" alt={club.title} />
                <div className="card-body">
                  <h5 className="card-title">{club.title}</h5>
                  <p className="card-text">{club.description}</p>
                  <button className="btn btn-primary" onClick={() => handleEventClick(club,index)}>
                  Register
                  </button> 
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      </>
    );
  }

export default AfterSchoolClubs;