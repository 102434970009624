// import AppNavbar from './Navbar';
import Jumbotron from './Jumbotron';
import Events from './Events';
import JoinUs from './JoinUs';
import Contact  from './Contact';
import Footer from './Footer';
import EventsList from './EventsList';
import {ApolloProvider,ApolloClient,HttpLink, InMemoryCache} from '@apollo/client';//methods needed to use Apollo Client
import {useState} from 'react';
import ErrorBoundary from './ErrorBoundry';
function Home(){

  //To fetch data using apollo-client from GraphQl api
    // initialize ApolloClient instance
    // const API_URL = process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337"; //specifies the URL of the GraphQL server
    // console.log(`URL: ${API_URL}`)
    // const [query, setQuery] = useState("");
    // console.log('query from seach box: '+query);
    // const link = new HttpLink({ uri: `${API_URL}/graphql`})
    // const cache = new InMemoryCache() //cache is what ApolloClient uses to temporarily store query results after fetching them
    // const client = new ApolloClient({link,cache});
    // const { user, login, logout } = useContext(AuthContext);
    // login('Shaima');
  

    return (
    <>
    {/* <AppNavbar /> */}
    <div style={{ display: "grid", rowGap: "20px" }}>
        <Jumbotron style={{ marginBottom: "60px" }} />
        <ErrorBoundary>
          <EventsList style={{ marginBottom: "60px" }} />
          </ErrorBoundary>
        
        {/* <JoinUs />
      <Contact /> */}
      <Footer/>
      </div>
      </>
        
    )

}

export default Home;