import React, { useState } from 'react';
import './CalenderWithEvents.css';
// import Navbar from './Navbar';
// Utility function to generate dates for the month
const generateCalendar = (year, month) => {
  const startDate = new Date(year, month, 1);
  const endDate = new Date(year, month + 1, 0);
  
  const dates = [];
  
  // Fill in days from the previous month (to align the first day)
  const startDay = startDate.getDay();
  for (let i = 0; i < startDay; i++) {
    dates.push(null);
  }
  
  // Fill in current month days
  for (let day = 1; day <= endDate.getDate(); day++) {
    dates.push(day);
  }
  
  return dates;
};

// Sample event data
const events = [
  { date: '2024-11-15', title: 'Team Meeting' },
  { date: '2024-11-20', title: 'Wednesday Waffles' },
  { date: '2024-10-25', title: 'Conference' }
];

const CalendarWithEvents = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);

  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  // Helper to move to previous month
  const previousMonth = () => {
    setCurrentDate(new Date(currentYear, currentMonth - 1));
    setSelectedDate(null);
  };

  // Helper to move to next month
  const nextMonth = () => {
    setCurrentDate(new Date(currentYear, currentMonth + 1));
    setSelectedDate(null);
  };

  // Helper to check if a day has events
  const hasEvent = (year, month, day) => {
    const dateString = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    return events.some(event => event.date === dateString);
  };

  // Handle day click
  const handleDayClick = (day) => {
    const dateString = `${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    setSelectedDate(dateString);
  };

  const calendarDays = generateCalendar(currentYear, currentMonth);
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  return (
    <>
    <div className="calendar-container">
      <div className="calendar-header">
        <button onClick={previousMonth}>Previous</button>
        <h2>{monthNames[currentMonth]} {currentYear}</h2>
        <button onClick={nextMonth}>Next</button>
      </div>

      <div className="calendar-grid">
        <div className="day-name">Sun</div>
        <div className="day-name">Mon</div>
        <div className="day-name">Tue</div>
        <div className="day-name">Wed</div>
        <div className="day-name">Thu</div>
        <div className="day-name">Fri</div>
        <div className="day-name">Sat</div>

        {calendarDays.map((day, index) => (
          <div 
            key={index} 
            className={`day ${day ? '' : 'empty'} ${day && hasEvent(currentYear, currentMonth, day) ? 'event' : ''}`}
            onClick={() => day && handleDayClick(day)}
          >
            {day}
          </div>
        ))}
      </div>

      {selectedDate && (
        <div className="events-list">
          <h3>Events on {selectedDate}:</h3>
          <ul>
            {events
              .filter(event => event.date === selectedDate)
              .map((event, index) => (
                <li key={index}>{event.title}</li>
              ))}
          </ul>
        </div>
      )}
    </div>
    </>
  );
  
};


export default CalendarWithEvents;
